
import React, { Component } from 'react';
import { PatternFormat } from 'react-number-format';
import {
    Button,
    Row,
    Col,
    CardText,
} from 'reactstrap'

import IGICoficialazul from './Image/IGICoficialazul.png'
import { MdOutlineManageSearch } from 'react-icons/md'
import { FaSpinner } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

export default class Header extends Component {
    constructor(props) {
        super(props)

        const myParam = window.location.pathname.split('/')[1];
        this.state = {
            loadingClinic: true,
            myParam: myParam,
            establishmentLoaded: true
        }
    }
    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    render() {
        const { myParam } = this.state
        const { establishmentLoaded, modelClinic } = this.props
        return (<div>
            {establishmentLoaded == false ? <div className='text-center mt-5'><FaSpinner size={45} color={'blue'} className="icon_pulse" />
            </div> :
                <div>
                    {/* <Navbar color="light" light expand="md">
                        <NavbarBrand href="/">reactstrap</NavbarBrand>
                        <NavbarToggler onClick={this.toggle} />
                        <Collapse isOpen={this.state.isOpen} navbar>
                            <Nav className="mr-auto" navbar>
                                <NavItem>
                                    <NavLink href={"/" + myParam + "/schedulingssearch"}>Meus Agendamentos</NavLink>
                                </NavItem>
                                <NavItem>
                                    <AiOutlineShoppingCart size={25} />
                                     <NavLink href="https://github.com/reactstrap/reactstrap">GitHub</NavLink>
                                </NavItem>

                            </Nav>
                        </Collapse>
                    </Navbar> */}
                    <Row className='justify-content-center'>
                        <Col sm="3" className=' m-auto'>
                            {/* <Card body className='border-0'> */}
                            <ImageHome modelClinic={modelClinic} myParam={myParam} />
                            {/* </Card> */}
                        </Col>
                        <Col sm="4" className='text-center my-auto'>
                            {/* <Card body className='border-0'> */}
                            <CardText tag="h5" className='m-0'>
                                <span>{modelClinic.corporateName}</span>
                            </CardText>
                            <CardText className='text-secondary m-0'>
                                {modelClinic.address} N. {modelClinic.number}{modelClinic.complement} - B. {modelClinic.bairro}
                            </CardText>
                            <CardText className='text-secondary m-0'>
                                {modelClinic.nameCity} / {modelClinic.nameState} -
                                CEP: {<PatternFormat
                                    format='##.###-###'
                                    displayType={'text'}
                                    value={modelClinic.zipCode}
                                    style={{ fontWeight: 'normal' }}
                                />}
                            </CardText>
                            <CardText className='text-secondary m-0'>
                                {<PatternFormat
                                    format='(##) #####-####'
                                    displayType={'text'}
                                    value={modelClinic.cellPhone}
                                    style={{ fontWeight: 'normal' }}
                                />}
                            </CardText>
                            {/* </Card> */}
                        </Col>
                        <Col sm="5" className='text-center my-auto'>
                            <div className="rbc-btn-group">
                                <Button
                                    size='sm'
                                    color="warning"
                                    className="btn-labeled"
                                    href={"/" + myParam + "/schedulingssearch"}
                                >
                                    <span className="btn-label"><MdOutlineManageSearch size={25} /></span> Meus Agendamentos
                                </Button>{' '}
                            </div>
                        </Col>
                    </Row>
                </div>}
        </div>);
    }
}
function ImageHome(props) {
    const navigate = useNavigate()

    const navigationHome = () => {
        navigate('/' + props.myParam)
    }
    return (
        <div
            onClick={e => navigationHome()}
            className='d-flex justify-content-center'>
            {<img
                className="img-fluid w-50 m-auto"
                src={props.modelClinic.file != undefined
                    ? "data:imageFile/png;base64," + props.modelClinic.file
                    : IGICoficialazul}
            />}
        </div>
    )
}