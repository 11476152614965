import React, { Component } from "react";
import { Route, Routes, } from "react-router-dom";
import Base from "./View/Layout/Base"
import BasePage from "./View/Layout/BasePage";
import LinkBundler from "./View/LinkBundler/LinkBundler";
import ErrorAlert from "./Shared/ErrorAlert";

const Router = () => {
    const currentKey = window.location.pathname.split('/')[1] || '/';
    // if (currentKey == "paymentplans") {
    //     return (
    //         <BasePage>
    //             <Routes>
    //                 {/* <Route path="/anamnese/:guid" element={<Anamnese />} /> */}
    //                 {/* <Route path="/paymentplans" index element={<Plans />} /> */}
    //             </Routes>
    //         </BasePage>
    //     )
    // } else {
    return (
        <BasePage>
            <Base>
                <Routes>
                    {/* <Route path="/:param1" index element={<Loading />} /> */}
                    {/* <Route path="/:param1" index element={<OptionsInitial />} /> */}
                    <Route path="/:alias" index element={<LinkBundler />} />
                    <Route path="" element={<ErrorAlert />} />
                </Routes>
            </Base>
        </BasePage>
    )
    // }
}
export default Router;

export class Loading extends Component {
    render() {
        return (<div className="text-center mt-5">Carregando...</div>)
    }
}