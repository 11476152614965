import React, { Component } from 'react';

import Header from './Header'

import axios from 'axios';
import './body.css'
import { URL_LinkClinic } from '../Services/LinksService';
class Base extends Component {

    constructor(props) {
        super(props)
        const myParam = window.location.pathname.split('/')[1];
        this.state = {
            loading: true,
            myParam: myParam,
            modelClinic: {},
            establishmentLoaded: false
        }
    }

    componentDidMount = async () => {
        await this.consultClinic()
    }

    consultClinic = async () => {
        const { myParam } = this.state
        if (myParam != '') {
            // await axios.get(`${URL_LinkClinic}/${myParam}/alias`).then(resp => {
            //     const { data } = resp
            //     this.setState({ modelClinic: data, establishmentLoaded: true })
            // }).catch(() => { throw new Error(); })
        }
        else
            this.setState({ establishmentLoaded: false });


        this.setState({ establishmentLoaded: true });
    }

    render() {
        const { modelClinic, establishmentLoaded } = this.state
        return (
            <div className="body">
                {/* <Header modelClinic={modelClinic}
                    establishmentLoaded={establishmentLoaded} /> */}
                {establishmentLoaded ?
                    <div>
                        <section className="section-container">
                            {this.props.children}
                        </section></div> : null}
                {/* <Footer /> */}
            </div>
        );
    }
}
export default Base;
