import { Component } from "react";
import "./LibellusSistemas.css";

export default class LibellusSystemas extends Component {
    render() {
        return (
            <div>
                <footer>Sistemas Libellus</footer>
            </div>
        )
    }
}