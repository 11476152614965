import { Component } from "react";
import { FiAlertTriangle } from 'react-icons/fi'

export default class ErrorAlert extends Component {
    render() {
        return (
            <div className="abs-center wd-xl">
                <div className="text-center mb-4">
                    <div className="text-lg mb-3 mt-3">
                        <FiAlertTriangle size={50} color="orange" />
                    </div>
                    <p className="lead m-0">A Empresa que você está tentando acessar não foi encontrada neste site.</p>
                    <p className="lead m-0"> Certifique-se de que a URL está correta.</p>
                    <p>Empresa não encontrada.</p>
                </div>

                <div className="p-3 text-center">
                    <span className="mr-2">&copy;</span>
                    <span>2022</span>
                    <span className="mx-2">-</span>
                    <span>Igic</span>
                    <br />
                </div>
            </div>
        )
    }
}