import axios from "axios";
import React, { Component } from "react";
import { URL_LinkClinic } from "../Services/LinksService";
import { Button, Col, Row, Spinner } from "reactstrap";
import ErrorAlert from "../../Shared/ErrorAlert";
import SystemaLibellus from "../../Shared/LibellusSystemas";
import LibellusSystemas from "../../Shared/LibellusSystemas";

export default class LinkBundler extends Component {

    state = {
        linkClinic: {},
        loading: true
    }

    async componentDidMount() {
        const aliasParam = window.location.pathname.split('/')[1];
        const linkClinic = (await axios.get(`${URL_LinkClinic}/${aliasParam}/alias`)).data;
        this.setState({ linkClinic, loading: false });
    }

    render() {
        const { linkClinic, loading } = this.state;
        // const color = linkClinic.clinic.barColor ?? '#000';
        const colorBlack = '#171821';
        const colorBar = linkClinic?.barColor ?? '#171821';
        const colorButton = linkClinic?.buttonColor ?? '#171821';
        const colorText = linkClinic?.textColor ?? 'white';
        return (
            loading ? <div className="d-flex m-4 p-4 justify-content-center align-content-center"><Spinner /></div> : !!linkClinic && !!linkClinic.clinic ?
                <div className="container-fluid">
                    <div style={{ height: '75px', backgroundColor: colorBar, borderBottom: '1px solid #F0F0F0' }}>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="border rounded-circle border-light" style={{ marginTop: '-50px', overflow: 'hidden', stroke: "#F0F0F0" }}>
                            <img src={`data:image/gif;base64,${linkClinic.clinic.file}`} style={{ objectFit: 'cover' }} width="100" height="100" alt="Imagem do Estabelecimento." />
                        </div>
                    </div>
                    <div className="d-flex text-center mt-2 justify-content-center align-content-center flex-column">
                        <h4 style={{ color: colorBlack, marginTop: '16px' }}>{linkClinic.clinic.corporateName}</h4>
                        <h6 style={{ color: colorBlack, marginBottom: '24px' }}>{linkClinic?.description ?? ''}</h6>
                    </div>
                    <div style={{ marginLeft: '8px', marginRight: '8px' }}>
                        <div className="text-center d-flex flex-column m-auto" style={{ maxWidth: '700px' }} >
                            {linkClinic.links.map((link, i) => <ButtonItem key={i} model={link} color={{ button: colorButton, text: colorText }} />)}
                        </div>
                    </div>
                    <div style={{ height: '100px' }}></div>
                    <LibellusSystemas />
                </div> : <ErrorAlert />
        )
    }
}

export class ButtonItem extends Component {
    render() {
        const { model, color } = this.props;

        return (
            <a
                style={{
                    backgroundColor: color.button,
                    border: 'none',
                    borderRadius: '4px',
                    padding: '16px',
                    marginTop: '12px',
                    boxShadow: '0.5px 0.5rem 1rem rgba(0,0,0,0.15)',
                    color: color.text
                }}
                block
                href={model.url}
                className="btn font-weight-bold"
            ><span style={{ color: color.text }}>{model.title}</span></a>
            // ><span style={{ color: 'white', mixBlendMode: 'difference' }}>{model.title}</span></a>
        )
    }
}